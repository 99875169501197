import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './style.scss';


class RegistrationSuccess extends PureComponent {

    onClick = () => this.props.onClick();

    render() {
        const titleText = <h2 className="big-text">נעים להכיר הלפר/ית!</h2>

        const button = this.props.fromVolunteer ? 'חזרה להתנדבות' : this.props.user && this.props.user.fbUser ? 'חזרה לעמוד הבית' : null;

        const subtitle = this.props.subtitle ? <h3>{this.props.subtitle}</h3> : null;
        const message = this.props.message ? this.props.message : null;

        return (
            <div className={`registration-success-wrapper ${this.props.className}`}>
                <div className="wrapper">
                <div className="text-wrapper">
                        <div className="right-image"></div>
                        <div className="left-image"></div>
                        <div className="container-text">
                        <span className='title-text'>
                        {titleText}
                        </span>
                        {subtitle}
                        {message}
                        { button &&
                            <div onClick={this.onClick} className={`return`}>{button}</div>
                        }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

RegistrationSuccess.propTypes = {
    onClick: PropTypes.func,
    fromVolunteer: PropTypes.bool,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

RegistrationSuccess.defaultProps = {
    message: null,
    subtitle: null
}

export default RegistrationSuccess;