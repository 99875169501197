import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  REQUEST_DESCRIPTION_MIN_LENGHT,
  REQUEST_DESCRIPTION_ROWS_NUM,
  ADDRESS_FIELDS,
  TIME_RANGES,
} from "../../common/constants";
import TextAreaInput from "../text-area-input";
import LocationAutoComplete from "../location-autocomplete";
import {
  DATE_RADIO_BUTTONS,
  DATE_RADIO_BUTTONS_TYPES,
  FORM_EXTRA_COMPONENTS_TYPES,
  AUTH_USER_STATE_BUTTONS,
} from "../../features/request/constants";
import Radio from "../radio";

import moment from "moment";
import DateTimeCasePicker from "../date-time-case-picker";
import { Link } from "react-router-dom";
import AuthConsumer from "../../core/containers/auth/auth-protected";
import CheckboxWrapper from "../checkbox-wrapper";
import InputValidator from "../../common/input-validator";
import MultiSelect from "../multi-select";
import HelpiButton from "../helpi-button";
import RequestFormWrapper from "../../core/containers/request-form";
import Utils from "../../common/utils";
import ValidatedInput from "../validated-input";

class RequestForm extends PureComponent {
  constructor(props) {
    super(props);

    this.geosuggestLocationRef = React.createRef();
    this.geosuggestDestinationRef = React.createRef();

    this.initialData = {
      behalfOtherPersonData: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      },
      multipleVolunteersData: "",
    };
    this.initialState = () => ({
      form: {
        description: {
          value: "",
          error: "",
          isValid: false,
        },
        userType: {
          value: AUTH_USER_STATE_BUTTONS[0].value,
          isValid: true,
        },
        location: {
          value: {
            address: {
              country: "",
              city: "",
              streetName: "",
              streetNumber: "",
            },
          },
          isValid: !this.hasComponentType(FORM_EXTRA_COMPONENTS_TYPES.LOCATION),
          active: this.hasComponentType(FORM_EXTRA_COMPONENTS_TYPES.LOCATION),
          error: "יש להזין (רחוב, מס׳ בית, עיר) ולבחור את הכתובת מהרשימה",
        },
        destination: {
          value: {
            address: {
              country: "",
              city: "",
              streetName: "",
              streetNumber: "",
            },
          },
          isValid: !this.hasComponentType(
            FORM_EXTRA_COMPONENTS_TYPES.DESTINATION
          ),
          active: this.hasComponentType(
            FORM_EXTRA_COMPONENTS_TYPES.DESTINATION
          ),
          error: "יש להזין (רחוב, מס׳ בית, עיר) ולבחור את הכתובת מהרשימה",
        },
        dateType: {
          value: DATE_RADIO_BUTTONS_TYPES.FLEX,
          isValid: true,
        },
        timeRange: {
          value: [],
          isValid: () => this.timeRangeValidate(),
        },
        date: {
          value:moment()
          .add(60, "day")
          .set({ hour: 11, minutes: 0, seconds: 0 }),
          isValid: true,
        },
        behalfOtherPersonData: {
          active: false,
          value: this.initialData.behalfOtherPersonData,
          isValid: true,
        },
        multipleVolunteersData: {
          active: false,
          value: this.initialData.multipleVolunteersData,
          isValid: true,
        },
        startRangeOfDates: {
          value: moment()
          .add(1, "day")
          .set({ hour: 11, minutes: 0, seconds: 0 }),
          isValid: true,
        },
        endRangeOfDates: {
          value: moment()
            .add(2, "day")
            .set({ hour: 11, minutes: 0, seconds: 0 }),
          isValid: true,
        },
      },
      formValid: false,
    });

    this.state = this.initialState();

    this.handleMultipleVolunteersChange =
      this.handleMultipleVolunteersChange.bind(this);

    this.toggleCheckBoxMultipleVolunteers = this.toggleCheckBoxField.bind(
      this,
      "multipleVolunteersData"
    );

    this.handleValidOtherPersonChange =
      this.handleValidOtherPersonChange.bind(this);
    this.handleFieldOtherPersonValueChange =
      this.handleFieldInnerFormValueChange.bind(this, "behalfOtherPersonData");
    this.toggleCheckBoxIsBehalfOtherPerson = this.toggleCheckBoxField.bind(
      this,
      "behalfOtherPersonData"
    );

    this.addressValidate = this.addressValidate.bind(this);
    this.handleDescriptionChangeAndValidation =
      this.handleDescriptionChangeAndValidation.bind(this);
    this.handleDateTimeCasePickerChange = this.handleFieldChange.bind(
      this,
      "date"
    );
    this.handleStartRangeOfDatesChange = this.handleFieldChange.bind(
      this,
      "startRangeOfDates"
    );
    this.handleEndRangeOfDatesChange = this.handleFieldChange.bind(
      this,
      "endRangeOfDates"
    );
    this.handleDateTypeChange = this.handleFieldChange.bind(this, "dateType");
    // this.handleTimeRangeChange = this.handleMultiSelectFieldChange.bind(this, 'timeRange');
    this.handleUserTypeChange = this.handleFieldChange.bind(this, "userType");

    this.timeRangeValidate = this.timeRangeValidate.bind(this);
  }

  clicked = false;

  isFormValid() {
    const { form } = this.state;
    return Object.values(form).reduce((acc, curr) => {
       
      if (typeof curr.isValid === "function") {
        return acc && curr.isValid();
      }
      return acc && curr.isValid;
    }, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item.type !== this.props.item.type) {
      this.setState(this.initialState());
      if (this.geosuggestLocationRef.current)
        this.geosuggestLocationRef.current.clear();
      if (this.geosuggestDestinationRef.current)
        this.geosuggestDestinationRef.current.clear();
    } else {
      this.setState({
        formValid: this.isFormValid(),
      });
    }
  }

  /**
   * handle a field change - adds the new value and the validation if sent
   * @param name
   * @param value
   * @param newError
   * @param isValueValid
   */
  handleFieldChange = (name, value, isValueValid, newError) => {

    const { form } = this.state;

    // const isStartDateValid = name === "startRangeOfDates" && value.d < form.endRangeOfDates.value
    const isValid = isValueValid !== undefined ? { isValid: isValueValid } : {};
    const error = newError !== undefined ? { error: newError } : {};

    if ((name == "startRangeOfDates" || name == "endRangeOfDates") && isValueValid ) {
        this.handleRangeOfDatesChange(form, name, value, isValid, error)
        return
    }
    
    this.setState({
      form: {
        ...form,
        [name]: {
          ...form[name],
          value: value,
          ...isValid,
          ...error,
        },
      },
    });
  };

  handleRangeOfDatesChange(form, name, value, isValid, error ){ 
    if(name === 'startRangeOfDates') {
        this.setState({
            form: {
                ...form,
                [name]: {
                ...form[name],
                value : value,
                ...isValid,
                ...error     
                } ,
                ['endRangeOfDates']: {
                    ...form['endRangeOfDates'],
                    isValid: true,
                    ...value,
                    ...error
                }
            } 
        });
    }  else {
        this.setState({
            form: {
                ...form,
                [name]: {
                ...form[name],
                value : value,
                ...isValid,
                ...error
                } ,
                ['startRangeOfDates']: {
                    ...form['startRangeOfDates'],
                    isValid: true,
                    ...value,
                    ...error
                }
            } 
        });
    }  
    };


  handleMultiSelectFieldChange = (name, value, isValueValid, newError) => {
    const { form } = this.state;

    const isValid = isValueValid !== undefined ? { isValid: isValueValid } : {};
    const error = newError !== undefined ? { error: newError } : {};

    const newArr = Utils.handleCheckboxClick(
      name,
      value,
      [...form[name].value],
      TIME_RANGES
    );

    this.setState({
      form: {
        ...form,
        [name]: {
          ...form[name],
          value: newArr,
          ...isValid,
          ...error,
        },
      },
    });
  };

  toggleCheckBoxField(fieldName) {
    const { form } = this.state;

    const { active } = form[fieldName];

    this.setState({
      form: {
        ...form,
        [fieldName]: {
          ...form[fieldName],
          value: this.initialData[fieldName],
          active: !active,
          isValid: active,
        },
      },
    });
  }

  toggleCheckBoxNoDataField(fieldName) {
    const { form } = this.state;

    const { active } = form[fieldName];

    this.setState({
      form: {
        ...form,
        [fieldName]: {
          ...form[fieldName],
          value: this.initialData[fieldName],
          active: !active,
          isValid: true,
          // isValid: active
        },
      },
    });
  }

  handleMultipleVolunteersChange(value) {
    const validatorResult = InputValidator.intRequiredPositiveMultiple(value);
    this.handleFieldChange(
      "multipleVolunteersData",
      value,
      validatorResult === "",
      validatorResult
    );
  }

  /**
   * handle when an address field is changed - adds the new address and the validation to the form
   * @param fieldName
   * @param addressValue
   * @param isValid
   * @param error
   */
  handleSuggestSelectLocation(fieldName, addressValue, isValid, error) {
    this.handleFieldChange(
      fieldName,
      {
        address: {
          ...addressValue,
        },
      },
      isValid,
      error
    );
  }

  /**
   * the validation decision for the description field and add the new value to the form
   * @param value
   * @returns {boolean}
   */
  handleDescriptionChangeAndValidation = (value) => {
    const isValid = value.length >= REQUEST_DESCRIPTION_MIN_LENGHT;
    this.handleFieldChange(
      "description",
      value,
      isValid,
      this.descriptionValidationText(value)
    );
  };

  /**
   * the text for the description field validation
   * @param value
   * @returns {string}
   */
  descriptionValidationText = (value) => {
    return value.length > 0
      ? value.length < REQUEST_DESCRIPTION_MIN_LENGHT
        ? `הערך שהוזן קצר מידי`
        : ""
      : "עליך למלא שדה זה בכדי לשלוח את הבקשה";
  };

  /**
   * checks if the date is in anther 2 days for the case task date date picker
   * */
  validateDate(date) {
    return (
      date.isAfter(moment().add(1, "day")) &&
      date.isBefore(moment().add(3, "month"))
    );
  }

  startValidateDate(_date){
    return (
      _date >= moment() &&
      _date.isBefore(moment().add(3, "month"))
    );
  }
  
  /**
   * when the validation from the asking for anther person changes
   *
   * */
  handleValidOtherPersonChange(isValid) {
    const { behalfOtherPersonData } = this.state.form;

    const isAllDataValid =
      !!behalfOtherPersonData.value.firstName &&
      !!behalfOtherPersonData.value.lastName &&
      isValid;

    this.handleValidInnerFormChange("behalfOtherPersonData", isAllDataValid);
  }

  /**
   * when the validation from the asking for anther person changes
   *
   * */
  handleValidInnerFormChange(formName, isValid) {
    const field = this.state.form[formName];

    this.setState({
      form: {
        ...this.state.form,
        [formName]: {
          ...field,
          isValid: isValid,
        },
      },
    });
  }

  /**
   * when the details from the asking for anther person changes
   *
   * */
  handleFieldInnerFormValueChange(formName, name, value) {
    const field = this.state.form[formName];
    const newFormValue = {
      ...field.value,
      [name]: value,
    };

    this.handleFieldChange(formName, newFormValue);
  }

  hasComponentType(type) {
    const { item } = this.props;
    return item.extraComponents.includes(type);
  }

  sendCaseRequest = () => {
    if (!this.clicked) {
      this.props.sendCaseRequest(
          this.state.form,
          this.props.user.id,
          this.props.item.type
          );
    }
    this.clicked = true;
  }

  isCityInWhiteList(city) {
    return this.props.cityWhiteList.indexOf(city) > -1;
  }

  /**
   * return true if the arrays have the same values - not necessary by the same order.
   * @param arr1
   * @param arr2
   * @returns {boolean|*}
   */
  isEqualArrays(arr1, arr2) {
    return (
      arr1.length === arr2.length &&
      arr1.reduce((acc, curr) => acc && arr2.includes(curr), true)
    );
  }

  /**
   * check if the city is in the white city list or if the address has all the fields, sets the currect indicator text and class, and aplay the prop handle change function.
   * @param addressObj
   * @param fieldName
   * @returns
   */
  addressValidate(addressObj, fieldName) {
    const isCityInWhiteList = this.isCityInWhiteList(addressObj.city);
    const isValid =
      this.isEqualArrays(
        Object.keys(addressObj),
        Object.values(ADDRESS_FIELDS)
      ) || isCityInWhiteList;

    if (isValid) {
      //if the city is in the white list add the extra empty fields(for the server)
      if (isCityInWhiteList) {
        const streetName = !addressObj.streetName
          ? {
              streetName: "",
            }
          : {};
        const streetNumber = !addressObj.streetNumber
          ? {
              streetNumber: "",
            }
          : {};

        addressObj = {
          ...addressObj,
          ...streetName,
          ...streetNumber,
        };
      }

      //Update the address value in the form
      this.handleSuggestSelectLocation(
        fieldName,
        addressObj,
        isValid,
        "הכתובת תקינה"
      );
    } else {
      //Update the address value in the form
      this.handleSuggestSelectLocation(
        fieldName,
        addressObj,
        isValid,
        "יש להזין (רחוב, מס׳ בית, עיר) ולבחור את הכתובת מהרשימה"
      );
    }
  }

  timeRangeValidate() {
    const { form } = this.state;
    const { timeRange, dateType } = form;

    return (
      dateType.value !== DATE_RADIO_BUTTONS_TYPES.FLEX ||
      timeRange.value.length > 0 ||
      !this.hasComponentType(FORM_EXTRA_COMPONENTS_TYPES.DATE)
    );
  }

  addChooseRenderElementsDateRadio = () => {
    const elements = [
      <div className="time-range-container">
        <MultiSelect
          generalFont = {true}
          id="timeRange"
          name="timeRange"
          placeholder="בחרו טווח שעות"
          data={Utils.addAllItem(TIME_RANGES)}
          onChange={this.handleMultiSelectFieldChange}
          value={this.state.form.timeRange.value}
        />
        {this.state.form.timeRange.value.length === 0 ? (
          <span>
            יש לבחור את טווח השעות הרלוונטי לביצוע ההתנדבות. ניתן לסמן יותר
            מאופציה אחת
          </span>
        ) : null}
      </div>,
      <div className={`date-time-container inner-section`}>
        <label className={`date-label`}>אנא בחרו תאריך ושעה</label>
        <DateTimeCasePicker
          validateDate={this.validateDate}
          defaultDate={this.state.form.date.value}
          handleDateTimeChange={this.handleDateTimeCasePickerChange}
        />
      </div>,
      <div className="date-time-container inner-section">
        <label className={`date-label start`}>אנא בחר תאריך התחלה</label>
        <DateTimeCasePicker
          validateDate={this.startValidateDate}
          defaultDate={this.state.form.startRangeOfDates.value}
          handleDateTimeChange={this.handleStartRangeOfDatesChange}
          name="startRangeOfDates"
          form={this.state.form}
        />
        <label className={`date-label end`}>אנא בחר תאריך סיום</label>
        <DateTimeCasePicker
          validateDate={this.validateDate}
          defaultDate={this.state.form.endRangeOfDates.value}
          handleDateTimeChange={this.handleEndRangeOfDatesChange}
          name="endRangeOfDates"
          form={this.state.form}
        />
        <span className={`${(!this.state.form.startRangeOfDates.isValid || !this.state.form.endRangeOfDates.isValid) ? `error` : ``}`} >{this.state.form.startRangeOfDates.error}{this.state.form.endRangeOfDates.error}</span>
      </div>,
    ];

    return DATE_RADIO_BUTTONS.map((item, i) => {
      item.elShowOnChoose = elements[i];
      return item;
    });
  };

  render() {
    const { item, user, whiteLabel } = this.props;
    const { form } = this.state;
    const dateRadioButtons = this.addChooseRenderElementsDateRadio();

    const hasLocation = this.hasComponentType(
      FORM_EXTRA_COMPONENTS_TYPES.LOCATION
    );
    const hasDestination = this.hasComponentType(
      FORM_EXTRA_COMPONENTS_TYPES.DESTINATION
    );
    const hasDate = this.hasComponentType(FORM_EXTRA_COMPONENTS_TYPES.DATE);

    return (
      <form id={`requestForm`}>
        <div className={`head-title inner-section`}>
          <h3>{item.label}</h3>
          <h4>{item.exampleCases}</h4>
        </div>
        <div className={`inner-section`}>
          <h3 className={`required-indicator`}>
            <strong>תיאור הבקשה</strong>
          </h3>
          <div className={`help-sub`}>
            <label>
              <strong>טיפ חשוב - </strong>
              <span>
                על מנת שנוכל למצוא מתנדב/ת במהירות, אנא מסרו כמה שיותר פרטים
                הנוגעים לבקשתכם.
              </span>
            </label>
            <label>סיכויי ההצלחה עולים בכ-40% לתיאורים שכוללים:</label>
            <ul>
              {item.prompts.map((prompt, promptIndex) => {
                return <li key={`prompt-${promptIndex}`}>{prompt}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className={`description-container text-input inner-section`}>
          <TextAreaInput
            label={"כאן כותבים את הבקשה"}
            required={true}
            rowsNum={REQUEST_DESCRIPTION_ROWS_NUM}
            handleFieldChange={this.handleDescriptionChangeAndValidation}
            fieldName={`description`}
            value={form.description.value}
            error={form.description.error}
          />
          <div className={`example-text`}>
            <strong>דוגמא לתיאור מוצלח:</strong>
            <p>{item.example}</p>
          </div>
        </div>
        <div className={`multiple-volunteers-container inner-section`}>
          <CheckboxWrapper
            label={`מתאים להתנדבות קבוצתית`}
            onChange={this.toggleCheckBoxMultipleVolunteers}
            checked={form.multipleVolunteersData.active}
          />
          {form.multipleVolunteersData.active && (
            <div className={`multiple-volunteers-input-container`}>
              <ValidatedInput
                error={form.multipleVolunteersData.error}
                value={form.multipleVolunteersData.value}
                required={true}
                name={"multiple-volunteers-input"}
                type={"number"}
                label={"מקסימום משתתפים"}
                onChange={this.handleMultipleVolunteersChange}
              />
            </div>
          )}
        </div>
        {(!whiteLabel || whiteLabel.type !== "organization") && (
          <div className="user-type-container inner-section">
            <h3 className="required-indicator">
              <strong>סוגי מתנדבים</strong>
            </h3>
            <Radio
              buttons={AUTH_USER_STATE_BUTTONS}
              handleChange={this.handleUserTypeChange}
              groupName={"userTypeRadioGroup"}
              currentValue={form.userType.value}
            />
          </div>
        )}
        <div className={`addresses-container inner-section`}>
          <h3 className={`required-indicator`}>
            <strong>כתובת</strong>
          </h3>
          {hasLocation && (
            <LocationAutoComplete
              isValid={form.location.isValid}
              error={form.location.error}
              name={"location"}
              addressValidate={this.addressValidate}
              geosuggestRef={this.geosuggestLocationRef}
              label={`אנא מלאו את כתובת המוצא`}
              placeholder={`כתובת המוצא`}
            />
          )}
          {hasDestination && (
            <LocationAutoComplete
              isValid={form.destination.isValid}
              error={form.destination.error}
              name={"destination"}
              addressValidate={this.addressValidate}
              geosuggestRef={this.geosuggestDestinationRef}
              label={`אנא מלאו את כתובת היעד`}
              placeholder={`כתובת היעד`}
            />
          )}
        </div>

        {hasDate && (
          <div className={`date-container inner-section`}>
            <h3 className={`required-indicator`}>
              <strong>מועד רצוי</strong>
            </h3>
            <Radio
              buttons={dateRadioButtons}
              handleChange={this.handleDateTypeChange}
              groupName={"dateRadioGroup"}
              currentValue={form.dateType.value}
            />
          </div>
        )}
        {user && (
          <div className={`user-info-container inner-section`}>
            <label>בכל עניין הקשור לבקשה, נדע להשיג אותך כאן:</label>
            <div className={`info`}>
              <label>
                שם מלא:{" "}
                <span className={`user-info-data`}>
                  {user.firstName} {user.lastName}
                </span>
              </label>
              <label>
                דוא"ל: <span className={`user-info-data`}>{user.email}</span>
              </label>
              {user.phoneNumber && (
                <label>
                  טלפון:{" "}
                  <span className={`user-info-data`}>
                    {user.phoneNumber.number}
                  </span>
                </label>
              )}
            </div>
            <label className={`update-label`}>
              הפרטים לא עדכניים? כדאי לעדכן אותם{" "}
              <Link
                className={`update-user-link`}
                to={`/profile`}
                target="_blank"
                rel="noopener noreferrer"
              >
                כאן{" "}
              </Link>
            </label>
          </div>
        )}
        <div className={`button-container inner-section`}>
          <HelpiButton
            label={`שלח/י בקשה`}
            disabled={!this.state.formValid}
            onClick={this.sendCaseRequest}
          />
        </div>
      </form>
    );
  }
}

RequestForm.propTypes = {
  item: PropTypes.object.isRequired,
  cityWhiteList: PropTypes.array,
};

export default RequestFormWrapper(AuthConsumer(RequestForm));
