import React from 'react';
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';


class FooterMenu extends React.PureComponent {

    /**
     * generate link according to item metadata
     * @param item
     * @returns {*}
     */
    static generateLink = (item ={}) => {

        if (item.hasOwnProperty('externalLink')) {
            const {externalLink} = item; 
      return  <a  className={item.classes} href={externalLink.link} target={externalLink.target ? externalLink.target : '_blank'} rel="noopener noreferrer">
          {externalLink.icon &&  <img src={externalLink.icon} alt={externalLink.alt}/> }
        <span>{item.label}</span>
           </a>
        }

        return <NavLink to={item.link}>{item.label}</NavLink>;
    };

    render() {
        return (
            <ul className="footer-links">
                {
                    this.props.items.map((item, index) => (
                        <li key={`${item.link}-${index}`} className={`link ${item.classes ? item.classes : ''}`}>
                            {FooterMenu.generateLink(item)}
                        </li>
                    ))
                }
            </ul>
        );
    }
}

FooterMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

export default FooterMenu;