/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from 'react';
import PropTypes from 'prop-types';

export class Stage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            errors: props.errors,
        };
    }

    render() {
        return (
            <>

                {this.props.children}
            </>
        );
    }
}

Stage.propTypes = {
    title: PropTypes.string
};

class Wizard extends React.Component {
    state = {
        activeStage: 0
    };

    componentDidUpdate(prevProps) {
        if (prevProps.activeStage !== this.props.activeStage) {
            this.setState({activeStage: this.props.activeStage});
        }
    }

    /**
     * go to next stage
     */
    next = () => {
        const max = this.props.children.length;

        this.setState({activeStage: Math.min(this.state.activeStage + 1, max)});
    };

    /**
     * go to previous stage
     */
    previous = () => {
        this.setState({activeStage: Math.max(this.state.activeStage - 1, 0)});
    };

    render() {

        const childrenWithProps = this.props.stages.map((stage, i) => (
            this.state.activeStage === i
                ?
                <Stage key={`stage-${i}`} title={stage.title}>
                    {React.cloneElement(stage.component, {next: this.next, previous: this.previous, totalStages: this.props.stages.length})}
                </Stage>
                :
                null
        ));

        return (
            <>
                {childrenWithProps}
            </>
        );
    }
}

Wizard.propTypes = {
    stages: PropTypes.array
};

Wizard.defaultProps = {
    stages: []
};

export default Wizard;
