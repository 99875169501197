import 'formdata-polyfill';
import React from "react";
import { Link } from 'react-router-dom';
import { HELPI_BUTTON_CLASSES, REGION_FILTER, TASK_TYPE_FILTER } from "../../common/constants";
import Utils from "../../common/utils";
import CheckboxWrapper from "../../components/checkbox-wrapper";
import EmailValidationModal from '../../components/email-validation-modal';
import HelpiButton from "../../components/helpi-button";
import MultiSelect from "../../components/multi-select";
import SimpleForm from "../../components/simpleForm";
import ValidatedForm from "../../components/validated-form";
import ProfileWrapper from "../../core/containers/auth/profile";
import history from "../../utils/history";
import InputValidator from './../../common/input-validator';
import './style.scss';

const FormFields = {
    firstName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם פרטי:',
        klass: 'auth-input'
    },
    lastName: {
        validator: InputValidator.name,
        type: 'text',
        label: 'שם משפחה:',
        klass: 'auth-input'
    },
    email: {
        validator: InputValidator.emailRequired,
        type: 'email',
        label: 'דוא"ל:',
        klass: 'auth-input',
        disabled: 'true'
    },
    phoneNumber: {
        validator: InputValidator.phoneRequestForm,
        type: 'number',
        label: 'טלפון:',
        klass: 'auth-input',
    },
    idNumber: {
        validator: InputValidator.id,
        type: 'number',
        label: 'תעודת זהות:',
        klass: 'auth-input',
    }
};

class Profile extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            valid: true,
            tasks: [],
            regions: [],
            caseNewsletter: false,
            sms: false,
            emailModal: false
        };

        this.basicForm = React.createRef();
        this.handleNewsletterClick = this.handleCheckboxClick.bind(this, 'caseNewsletter');
        this.handleSms = this.handleCheckboxClick.bind(this, 'sms');
    }

    componentDidUpdate(prevProps){
        const {user} = this.props;

        //redirect to HP if the user logged out
        if(user !== prevProps.user && !user){
            history.navigate('/');
        }

        if(user && user !== prevProps.user)
            this.setUserExtraData(user);

        if(!prevProps.showEmailModal && this.props.showEmailModal) {
            this.setState({...this.state, emailModal: true})
        }
    }

    componentDidMount(){
        const {user} = this.props;
        if(user)
            this.setUserExtraData(user);
    }

    RemoveSubRegionCenter (UserRegions){
        //  filter only the regions that are in the region filter
        let regions = UserRegions.filter(region => REGION_FILTER.some(reg => reg.value === region));
        //  if the user has a region from the old sub center region, add the center region
        const oldSubCenterRegion = [,'gush_dan','lowland_coast','sharon']
       if (UserRegions.some(region => oldSubCenterRegion.includes(region)) && !regions.includes('center')){
            regions.push('center');     
       }
        return regions;
    }
    setUserExtraData(user){
        if(user)
       
            this.setState({
                tasks: user.taskTypes ? user.taskTypes: [],
                regions: user.regions ?  this.RemoveSubRegionCenter(user.regions): [],                
                caseNewsletter: user.notifications ? user.notifications.caseNewsletter: false,
                sms: user.notifications ? user.notifications.sms : false
            })
    }

    onValidChange = (valid) => this.setState({ valid });

    handleFieldValueChange = () => {
        if(this.props.validError !== '' && this.props.resetValidError){
            this.props.resetValidError();
        }
    };

    onSubmit = (evt) => {
        const {sms, caseNewsletter, tasks, regions} = this.state;

        evt.preventDefault();

        const formData = new FormData(evt.target);

        this.props.onSubmit({
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            phoneNumber: formData.get('phoneNumber'),
            email: this.props.user.email,
            idNumber: formData.get('idNumber'),
            taskTypes: tasks,
            regions,
            sms,
            caseNewsletter,
        });

    };

    handleCheckboxClick = (fieldName) => this.setState({...this.state, [fieldName]: !this.state[fieldName]});

    addDefaultValuesToForm = (entity, FormFields) => {
        let fields = FormFields;
        if(entity){
            Object.keys(FormFields).forEach((key) => {
                fields[key].defaultValue = (key === 'phoneNumber') ? (entity[key]) ? entity[key].number : '' : entity[key];
            });

        }
        return fields;
    };

    onMultiSelectSelect = (name, value) => {
        let values;
        const filter = name === 'tasks' ? TASK_TYPE_FILTER : REGION_FILTER;

        if(value === '*all*') {
            values = this.state[name].length === filter.length ? [] : filter.map((item) => item.value);
        } else if (this.state[name].indexOf(value) > -1) {
            values = this.state[name].filter((item) => item !== value);
        } else {
            values = [...this.state[name], value];
        }

        this.setState({...this.state, [name]: values})
    };

    getCompanyFields = () => {

        const {company} = this.props.user;
        return (
            {
                companyName: {
                    defaultValue: company.name,
                    type: 'text',
                    label: 'שם:',
                    required: false,
                    klass: 'auth-input',
                    disabled: true
                },
                title: {
                    type: 'title',
                    title: 'משתמש אחראי',
                },
                contactPersonName: {
                    defaultValue: (company.contactPerson) ? company.contactPerson.name : '',
                    type: 'text',
                    label: 'שם:',
                    required: false,
                    klass: 'auth-input',
                    disabled: true
                }
                ,
                contactPersonEmail: {
                    defaultValue: (company.contactPerson) ? company.contactPerson.email : '',
                    type: 'email',
                    label: 'אימייל:',
                    required: false,
                    klass: 'auth-input',
                    disabled: true
                },
                contactPersonPhoneNumber: {
                    defaultValue: (company.contactPerson) ? company.contactPerson.phoneNumber.number : '',
                    type: 'number',
                    label: 'טלפון:',
                    required: false,
                    klass: 'auth-input',
                    disabled: true
                }

            });
    };

    handleGenerateEmailToken = () => this.props.generateEmailToken(this.props.user.id);

    toggleEmailModal = () => this.setState({...this.state, emailModal: !this.state.emailModal});


    render() {
        const { caseNewsletter, tasks, regions, emailModal} = this.state;

        const {user, validError, showSmsModal} = this.props;

        // const hasSecondSection = user && (user.company);
        /**
         * TEMP!! Temp removing company section in profile until email validation is developed
         **/
        const hasSecondSection = false;

        const secondSectionTitle = (user && user.company) ? 'פרטי חברה' : "פרטי ארגון";

        const defaultValueToForm = (!user) ? FormFields : this.addDefaultValuesToForm(user, FormFields);

        const error = validError && <div className="error-message">{validError}</div>;

        return (
                user && <div className={`container profile-container`}>
                    <div className={`user-form`}>
                        <h2>{user.firstName} {user.lastName}</h2>
                        {/*<div className="profile-container-my_tasks">*/}
                        {/*    <Link to="/my-tasks" target="_blank" rel="noopener noreferrer">ההתנדבויות שלי</Link>*/}
                        {/*</div>*/}
                        <form onSubmit={this.onSubmit} className="basic-details-form" ref={this.basicForm}>
                            <ValidatedForm
                                onValidChange={this.onValidChange}
                                fields={defaultValueToForm}
                                isValidOnStart={false}
                                handleFieldValueChange={this.handleFieldValueChange}
                            />
                            <MultiSelect
                                name={'tasks'}
                                label={'איזה התנדבויות מעניינות אותך:'}
                                labelAbove={true}
                                value={tasks}
                                coloredCheckbox={'primary'}
                                noScroll={true}
                                labelInDropdown={false}
                                alignOnBottom={true}
                                placeholder={"אפשר לבחור יותר מאפשרות אחת"}
                                data={Utils.addAllItem(TASK_TYPE_FILTER)}
                                onChange={this.onMultiSelectSelect}
                            />
                            <MultiSelect
                                name={'regions'}
                                label={"איפה נוח לך:"}
                                labelAbove={true}
                                value={regions}
                                coloredCheckbox={'primary'}
                                labelInDropdown={false}
                                noScroll={true}
                                alignOnBottom={true}
                                placeholder={"אפשר לבחור יותר מאפשרות אחת"}
                                data={Utils.addAllItem(REGION_FILTER)}
                                onChange={this.onMultiSelectSelect}
                            />
                            <div className="checkbox">
                                <CheckboxWrapper
                                    colored={'primary'}
                                    label={'אני רוצה לקבל עדכונים מהניוזלטר.'}
                                    checked={caseNewsletter}
                                    onChange={this.handleNewsletterClick}
                                />
                            </div>
                            <Link to="/profile/password">שינוי סיסמה</Link>
                            <div className="under-text">{!showSmsModal ? error : ''}</div>
                            <HelpiButton disabled={!this.state.valid}
                                         type={'submit'}
                                         label={'עדכון'}
                                         classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}
                            />
                        </form>
                        <EmailValidationModal
                            show={emailModal}
                            generateToken={this.handleGenerateEmailToken}
                            generateSuccess={true}
                            handleToggle={this.toggleEmailModal}
                        />
                    </div>
                    {hasSecondSection &&
                    <div className={`company-details`}>
                        <h2>{secondSectionTitle}</h2>
                        <SimpleForm fields={this.getCompanyFields()}/>
                    </div>
                    }

                </div>
        );
    }
}

export default ProfileWrapper(Profile);
