import React from 'react';
import './style.scss';
import {connect} from "react-redux";
import RequestForm from "../../components/request-form";
import AuthConsumer from '../../core/containers/auth/auth-protected';
import TaskTypesGrid from '../../components/task-types-grid';
import history from '../../utils/history';
import {SIGN_UP_CONTEXTS, USER_ROLES} from '../../common/constants';
import EmergencyRequestForm from '../../components/emergency-request-form';
import * as rootSelector from '../../core/containers/root-reducer/selectors';
import redirectShortPassword from '../../common/redirect-short-password';

const mapStateToProps = (state) => ({
    whiteLabel: rootSelector.selectWhiteLabel(state)
})

class Request extends React.PureComponent {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            activeRequest: null
        }
    }

    componentDidMount(){
        window.scroll(0,0);
        redirectShortPassword(this.props.user, this.props.goToUpdatePassword);
    }

    setActiveRequest = (item) => {
        this.setState({
            activeRequest: item
        });
        this.scrollToForm();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevActive = prevState.activeRequest;
        const {user} = this.props;
        if (prevActive === null) {
            this.scrollToForm();
        }
        if (prevProps.user && !this.props.user) {
            this.leave('/ask-help');
        } else if (!prevProps.user && !user && this.props.authTestDone) {
            this.leave('/');
        } else if (!prevProps.user && user && (!user.role || user.role !== USER_ROLES.SOCIAL_WORKER || user.role !== USER_ROLES.ADMIN)){
            history.navigate('/');
        }
    }

    leave = (redirect) => {
        history.navigate({
            pathname: '/signin',
            state: {
                context: SIGN_UP_CONTEXTS.FULL,
                redirectPath: redirect
            }
        });
    };

    scrollToForm() {
        window.scroll({
            top: (this.formRef.current) ? this.formRef.current.offsetTop - 90 : 0,
            behavior: "smooth"
        })
    }

    render() {        
        const {activeRequest} = this.state;
        const activeRequestType = (activeRequest) ? activeRequest.type : '';

        const form = this.props.whiteLabel && this.props.whiteLabel.emergency
            ?
            <EmergencyRequestForm/>
            :
         <div className={`container ask-help-container ask-help`}>
                <div id={`title-container`}>
                    <h2>טופס בקשה</h2>
                </div>
                <TaskTypesGrid
                    activeRequest={activeRequestType}
                    onClick={this.setActiveRequest}
                />
                <div className={"request-form-container"} ref={this.formRef}>{
                    activeRequest &&
                    <RequestForm item={activeRequest}/>
                }
                </div>
            </div>

        return form;
    }
}


export default connect(mapStateToProps)(AuthConsumer(Request));
