/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description: Auth box for login / signup
 */
import React from 'react';
import PropTypes from 'prop-types';
import ValidatedForm from '../validated-form';
import InputValidator from "../../common/input-validator";
import Utils from "../../common/utils";
import './style.scss';
import HelpiButton from "../helpi-button";
import {HELPI_BUTTON_CLASSES, EVENTS} from '../../common/constants';
import ForgotPasswordModal from '../forgot-password-modal';
import FinalDetails from '../signup-box/final-details';
import GAService from '../../common/analytics-service';
// import HelpiReCaptcha from '../helpi-recaptcha';
import 'formdata-polyfill';
import ReCAPTCHA from 'react-google-recaptcha'


const FormFields = {
    email: {
        validator: InputValidator.email,
        type: 'email',
        // label: 'אימייל:',
        hint: 'אימייל',
        klass: 'auth-input new'
    },
    password: {
        validator: InputValidator.passwordLogin,
        type: 'password',
        // label: 'סיסמה:',
        hint: 'סיסמה',
        klass: 'auth-input new'

    },
   
    
};

class AuthBox extends React.PureComponent {

    constructor(props) {
        super(props);

        this.reCaptcha = React.createRef();

        this.state = {
            data: Utils.buildFormScheme(FormFields),
            valid: true,
            forgetPassword: false,
            finalDetails: false,
            token: null
        };
    }

    onValidChange = (valid) => this.setState({ ...this.state, valid });

    componentDidUpdate(prevProps) {
        if(this.props.loginCompleteDetails && !prevProps.loginCompleteDetails) {
            this.props.handleAddUserDetails();
        }
    }

    /**
     * invoked on form submit
     * @param evt
     */
    onSubmit = async (evt) => {
        evt.preventDefault();

        if(this.props.validError) {
            this.props.resetValidError();
        }
        const data = new FormData(evt.target);

        const redirect = this.props.loginContext ? this.props.loginContext : true;

        const token = await this.reCaptcha.current.getValue();

        if(token) {
            this.props.onSubmit(
                {email: data.get('email'), password: data.get('password'), redirect: redirect, recaptchaToken: token});
        }
        // this.reCaptcha.current.execute();
    };

    /**
     * FB login
     */
    onFBClick = () => {
        const redirect = this.props.loginContext ? this.props.loginContext : true;

        this.props.onFBSubmit({redirect: redirect, recaptchaToken: this.state.token});

        GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.FB_CONNECT})
    };


    /**
     * toggle forget password modal
     */
    toggleForgotPassword = () => {

        this.setState({...this.state, forgetPassword: !this.state.forgetPassword});
    };

    onGoogleVerify = (token) => {

        this.setState({...this.state,  token})
    }
    onChangeCaptcha = (token) => {

        this.setState({...this.state,  token})
    }

    render() {

        const {forgetPassword, switchStates, forgetPasswordRequest, resetForgetPassword, validError} = this.props;

        const validErrorMessage = validError ? <div className="error-message">{validError}</div> : null;

        return (
            <div className={'auth-box'}>
                {   !this.props.loginCompleteDetails &&
                <div className='wrapper-form-login'>
                    <h4>טוב לראות אותך שוב</h4>
                    <form name={'login'} onSubmit={this.onSubmit}>
                        <ValidatedForm fields={FormFields} onValidChange={this.onValidChange}/>
                        <div className="under-fields">
                            <div className="forgot-password" onClick={this.toggleForgotPassword}>שכחתי סיסמה</div>
                            <div className="forgot-password" onClick={switchStates}>עדיין לא הצטרפת?</div>
                            {validErrorMessage}
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={this.reCaptcha}
                            onChange={this.onChangeCaptcha}
                            action="Login"
                        />
                        <div className="buttons">
                            <HelpiButton disabled={!this.state.valid || !this.state.token}
                                         label={'התחברות'}
                                         type={'submit'}
                                         classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}
                            />
                            <HelpiButton
                                label={"התחברות מהירה"}
                                action={'login'}
                                classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.FB, HELPI_BUTTON_CLASSES.BIG]}
                                onClick={this.onFBClick}
                            />
                        </div>
                    </form>
                    <ForgotPasswordModal
                        handleToggle={this.toggleForgotPassword}
                        currState={forgetPassword}
                        sendRequest={forgetPasswordRequest}
                        show={this.state.forgetPassword}
                        resetForgetPassword={resetForgetPassword}
                        type={'request'}
                        successMessage={'הוראות לאיפוס הסיסמה נשלחו במייל.'}
                        successTitle={'תודה!'}
                    />

                </div>
                }
                {
                    this.props.loginCompleteDetails &&
                        <FinalDetails
                            {...this.props}
                        />
                }
                {/* <HelpiReCaptcha
                    ref={this.reCaptcha}
                    onChange={this.onChangeCaptcha}
                    action="Login"
                    onVerify={this.onGoogleVerify}
                /> */}
            </div>
        );
    }
}

AuthBox.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    forgetPassword: PropTypes.shape({
        sent: PropTypes.bool,
        valid: PropTypes.bool
    }),
    switchStates: PropTypes.func,
    forgetPasswordRequest: PropTypes.func,
    resetForgetPassword: PropTypes.func,
    loginContext: PropTypes.object,
    loginCompleteDetails: PropTypes.bool
};

export default AuthBox;
