import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import line from "../../assets/img/icons/line.svg";


class TaskDataItem extends PureComponent {

    render() {
        const {className, label, text,icon,separator} = this.props;
        console.log(label);
        
        return(
            <div className={className}>
               {icon && <span className={`icon-task`}  >{<img src={icon} alt="icon-task" />} </span>}
               {label && <span className={`text-emphasized`}>{label} </span>}
                <span>{text}</span>
                {separator && <div className='separator'><img src={line} alt="separator"/></div>}
            </div>
        )
    }
}

TaskDataItem.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.string
};

export default TaskDataItem;