import React, {Component} from 'react';
import {connect} from "react-redux";
import history from './../../../utils/history';
import AuthConsumer from './auth-protected';
import * as selectors from "./selectors.js";
import * as actions from './actions';
import * as signUpActions from './sign-up/actions';
import * as actionTypes from './action-types';
import * as signUpActionTypes from './sign-up/action-types';
import * as rootActionTypes from '../root-reducer/action-types';
import {generateEmailToken} from '../homepage/actions';
import {setSpecialHeaderClass} from '../root-reducer/actions';

export default (AuthGui) => {
    const mapStateToProps = (state) => ({
        errors: selectors.selectAuthErrors(state),
        signUpStage: selectors.selectSignUpStage(state),
        emailDomainAuthorized: selectors.selectEmailDomainAuthorized(state),
        companies:selectors.selectCompanies(state),
        emailDomainType: selectors.selectEmailDomainAuthorizedType(state),
        forgetPassword: selectors.selectForgetPassword(state),
        verificationStage: selectors.selectVerificationStage(state),
        registrationSuccess: selectors.selectRegistrationSuccess(state),
        pendingTask: selectors.selectPendingTask(state),
        completeDetails: selectors.selectCompleteDetails(state),
        validError: selectors.selectValidError(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        login: ({email, password, redirect, recaptchaToken}) => dispatch(actions.login({email, password, redirect, recaptchaToken})),
        signUp: ({fb, email, password, company, signUpToken, loginToken,firstName, lastName,regions,taskTypes, caseNewsletter,recaptchaToken}) => dispatch(signUpActions.signUp({fb, email, password, company, signUpToken, loginToken,firstName, lastName,regions,taskTypes,caseNewsletter,recaptchaToken})),
        updateUser: (payload, sendVerification, isFinalStage, context) => dispatch(signUpActions.updateUser(payload, sendVerification, isFinalStage, context)),
        handlePendingTask: (redirect, user) => dispatch(actions.handlePendingTask(redirect, user)),
        fbLogin: (redirect) => dispatch(actions.facebookLogin(redirect)),
        forgetPasswordRequest: (email) => dispatch(actions.forgetPassword(email)),
        resetForgetPassword: () => dispatch({type: actionTypes.RESET_FORGOT_PASSWORD}),
        checkSMSCode: (payload, context) => dispatch(signUpActions.checkSMSCode(payload, context)),
        resendSMSCode: () => dispatch(signUpActions.resendSMSCode()),
        resetRegistration: () => dispatch({type: signUpActionTypes.RESET_REGISTRATION}),
        toggleLogoColor: () => dispatch({type: rootActionTypes.WHITE_HEADER_LOGO}),
        resetLogoColor: () => dispatch({type: rootActionTypes.RESET_LOGO}),
        resetValidError: () => dispatch({type: actionTypes.RESET_VALID_ERROR}),
        authTest: () => dispatch(actions.authTest()),
        checkEmailDomain: (email) => dispatch(signUpActions.checkEmailDomain(email)),
        setSpecialHeaderClass: (klass) => dispatch(setSpecialHeaderClass(klass)),
        resetHeaderClass: () => dispatch({type: rootActionTypes.RESET_HEADER_CLASS}),
        generateEmailToken: (userId) => dispatch(generateEmailToken(userId))
    });

    class AuthWrapper extends Component {

        /**
         * leave login page
         */
        leave = () => {
            const {state} = this.props.location;
            if(!state || !state.returnPath || state.returnPath === '/ask-help' || state.returnPath === '/profile'){
                history.navigate('/');
            } else {
                history.navigate(this.props.location.state.returnPath)
            }
        };

        render() {
            return (
                <AuthGui login={this.props.login}
                         signUp={this.props.signUp}
                         errors={this.props.errors}
                         signUpStage={this.props.signUpStage}
                         verificationStage={this.props.verificationStage}
                         handlePendingTask={this.props.handlePendingTask}
                         pendingTask={this.props.pendingTask}
                         updateUser={this.props.updateUser}
                         fbLogin={this.props.fbLogin}
                         forgetPasswordRequest={this.props.forgetPasswordRequest}
                         forgetPassword={this.props.forgetPassword}
                         resetForgetPassword={this.props.resetForgetPassword}
                         checkSMSCode={this.props.checkSMSCode}
                         resendSMSCode={this.props.resendSMSCode}
                         registrationSuccess={this.props.registrationSuccess}
                         resetRegistration={this.props.resetRegistration}
                         toggleLogoColor={this.props.toggleLogoColor}
                         resetLogoColor={this.props.resetLogoColor}
                         loginCompleteDetails={this.props.completeDetails}
                         validError={this.props.validError}
                         resetValidError={this.props.resetValidError}
                         authTest={this.props.authTest}
                         leave={this.leave}
                         emailDomainAuthorized={this.props.emailDomainAuthorized}
                         emailDomainType={this.props.emailDomainType}
                         checkEmailDomain={this.props.checkEmailDomain}
                         setSpecialHeaderClass={this.props.setSpecialHeaderClass}
                         resetHeaderClass={this.props.resetHeaderClass}
                         generateEmailToken={this.props.generateEmailToken}
                         companies={this.props.companies}
                />
            );
        }
    }


    return connect(mapStateToProps, mapDispatchToProps)(AuthConsumer(AuthWrapper));
};
