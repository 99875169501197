import React from 'react';
import Modal from  '../modal';
import './style.scss';

const EmailValidationModal = ({show, handleToggle, cases=0, valid=false, generateToken, generateSuccess=false, onSignUp=false,onCaseRegister=false,closeGenerateTokenModal}) => {

    const handleGenerateToken = () => {
        generateToken();
         handleToggle();

    }

    const validText = valid
        ?
        <div>האימות בוצע בהצלחה</div>
        :
        <div>
            <div>מצטערים, משהו השתבש באימות כתובת הדוא"ל שלך.</div>
            <div>לשליחת מייל אימות נוסף <span onClick={handleGenerateToken}>לחץ כאן</span></div>
        </div>;

    const casesText = cases ? <div>פרטים על ההתנדבות אצלך במייל</div> : null;

    const text = generateSuccess
        ?
        <div>
            <div>מייל אימות מחכה לך בכתובת הדוא"ל שלך.</div>
            <div>לא קיבלת? לשליחה חוזרת <span onClick={handleGenerateToken}>לחצ/י כאן</span></div>
        </div>
        :
        onCaseRegister?
        <div>
        <div>להשלמת התהליך יש לאמת את המייל <span onClick={handleGenerateToken}>לשליחת מייל</span> </div>
        <div>כדאי לבדוק גם את תיקיית הספאם.</div>
    </div>
    :
        onSignUp
            ?
            <div>
                <div>איזה כיף שבחרת בהתנדבות הזאת! <br/>רגע לפני שיוצאים לדרך, מייל אימות מחכה לך בכתובת הדוא"ל שלך.</div>
                <div>לא קיבלת? לשליחה חוזרת <span onClick={handleGenerateToken}>לחצ/י כאן</span></div>
            </div>
            :
        <>{validText}{casesText}</>;


    return (
        <div className="email-validation-modal">
            <Modal
                title={'את/ה הלפר/ית!'}
                show={show}
                handleToggle={handleToggle}
                className={'email-validation-modal'}
                render={props => <div className="child-modal">{text}</div>}
            />
        </div>
    )
};

export default EmailValidationModal;