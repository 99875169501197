/**
 * Created by mor on 03/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import RestService from "../../../common/rest-service";
import Cookies from 'universal-cookie';
import {IS_SUBDOMAIN, USER_ROLES} from "../../../common/constants"

class UserService {

    /**
     * get user Tasks from server
     * @param user id
     * @returns {Promise<*>}
     */
    getAllUserTasks = async ({status}) => await RestService.get(`api/user/task_list/${status}`);

    /**
     * get user data from server
     * @returns {Promise<*>}
     */
    getUserDetails = async () => await RestService.get('api/users/me',);

    /**
     * authenticate with server
     * @param email
     * @param password
     * @returns {Promise<*>}
     */
    authenticate = async ({email, password, recaptchaToken}) => await RestService.post('login', {email, password, ...this.prepRecaptcha(recaptchaToken)});

    /**
     * create a new user
     * @param email
     * @param password
     * @param firstName
     * @param lastName
     * 
     * @returns {Promise<*>}
     */
    create = async (company ,email, password, firstName, lastName, regions, tasks, caseNewsletter,recaptchaToken) => {
        const payload = {
            company,
            email,
            password,
            company,
            first_name:firstName, 
            last_name:lastName, 
            regions, 
            task_types:tasks, 
            notifications:{case_newsletter:caseNewsletter},
            ...this.prepRecaptcha(recaptchaToken)
        };
        if (!company){
           delete payload.company
        }

        if (sessionStorage.getItem('institute_id')) {
            const authorizedBy = {
                institute_id: sessionStorage.getItem('institute_id'),
                institute_type: sessionStorage.getItem('institute_type').toUpperCase()
            };
            const company = authorizedBy.institute_type === 'COMPANY' ? {
                id: authorizedBy.institute_id
            } : undefined;
            const organization = authorizedBy.institute_type === 'ORGANIZATION' ? {
                id: authorizedBy.institute_id
            } : undefined;

            payload.authorized_by = authorizedBy;
            payload.company = company;
            payload.organization = organization;

            if (sessionStorage.getItem('institute_type') === 'organization') {
                payload.role = USER_ROLES.SOCIAL_WORKER
            }
        }

        return await RestService.post('api/users', payload)
    };

    /**
     * update an existing user
     * @param id
     * @param payload
     * @returns {Promise<*|void>}
     */
    update = async (id, payload) => await RestService.put(`api/users/${id}`, payload);

    /**
     * remove token from local storage
     * @returns {Promise<string>}
     */
    logOut = async (id) => {           
        await RestService.post('api/logout');     
        const cookie = new Cookies();
        if (cookie.get('token')) {
            cookie.remove('token', {domain: `.${IS_SUBDOMAIN ? document.domain.split('.').slice(1).join('.') : document.domain}`, path: '/'});
        }
    };

    sendSMSVerification = async (id) => await RestService.post(`api/sms_check`, {user_id: id, action: 'send'});

    checkSMSVerification = async (id) => await RestService.post(`api/sms_check`, {user_id: id, action: 'check'});

    updatePassword = async ({oldPass, newPass, userId, token}) => (
        await RestService.put(`api/users/${userId}/check_password`,
            {old_pass: oldPass, new_pass: newPass, ...this.prepRecaptcha(token)})
    )

    checkRecaptcha = async (token) => await RestService.post('api/recaptcha', {'g-recaptcha-response': token});

    prepRecaptcha = (token) => ({'g-recaptcha-response': token})

    securityPasswordLength = async ({id, password}) => await RestService.put(`api/users/${id}/security_password_length`, {'user_id': id, 'password':password});
}

export default new UserService();
