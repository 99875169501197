import * as actionTypes from './action-types';
import Mapper from './mapper';
import MapperVolunteers from './volunteers-mapper';
import moment from 'moment';
import CaseService from './service';
import { selectLoggedInUserId, selectLoggedInUserRole, selectLoggedInUserData, selectLoggedInReqActionsEmail} from "../auth/selectors";
import {showErrorToast, showSuccessToast} from "../common/toast/actions";
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import history from './../../../utils/history'
import {USER_ROLES, EVENTS, ERROR_MESSAGE_TRANSLATE} from "../../../common/constants";
import GAService from '../../../common/analytics-service';

/**
 * fetch cases from server
 * @returns {Function}
 */
export const getCase = (serial) => async (dispatch, getState) => {

    dispatch(showLoading());
    try {

        const response = await CaseService.getCaseBySerial(serial);
        const {data} = response;

        const caseData = Mapper.mapToVm(data);

        const currentUserRole = selectLoggedInUserRole(getState());
        const currentUserId = selectLoggedInUserId(getState());

        let groupVolunteers = {};

        if ((currentUserRole === USER_ROLES.ADMIN ||currentUserRole === USER_ROLES.RESPONSIBLE ||  (!!caseData.whiteLabel && caseData.tasks[0].volunteerId === currentUserId))) {
            try {
                const list = await CaseService.getCaseGroupVolunteers(caseData.id);
                groupVolunteers = MapperVolunteers.mapToVm(list.data);
            }
            catch (e) {

            }
        }
        dispatch({type: actionTypes.GET_CASE_SUCCESS, payload: {...caseData, ...groupVolunteers}});
        dispatch(hideLoading());

    } catch (e) {
        dispatch(hideLoading());
    }
};

export const getCaseSerial = (id) => async (dispatch) => {
    const response = await CaseService.getCase(id);
    const {data} = response;

    if(data) {
        dispatch({type: actionTypes.GET_CASE_SERIAL, payload: {serialNumber: data}})
    }
}

/**
 * send the user to the server and add him to the casecase
 * @param caseId
 * @param taskId
 * @param redirectUrl
 * @returns {Function}
 */
export const addVolunteerToCase = (caseId, taskId) => async (dispatch, getState) => {

    const params = {
        sign_up: moment(),
        volunteer_id: selectLoggedInUserId(getState()),
        previous_volunteer_id: selectLoggedInUserId(getState())
    };
    dispatch(showLoading());

    try {
        const response = await CaseService.updateTask(caseId, taskId, params);
        if(response.data.num_cases && response.data.num_cases >= 3){
            dispatch({type: actionTypes.TOO_MANY_VOLUNTEERING})
            return 
        }
        const caseData = Mapper.mapToVm(response.data);

        if(response) {
            GAService.event({category: EVENTS.CASES.DISPLAY_NAME, action: EVENTS.CASES.ACTIONS.GET_INVOLVED_SUCCESS})
        }

        dispatch({ type: actionTypes.GET_CASE_SUCCESS, payload: caseData });
        dispatch({type: actionTypes.TOGGLE_THANK_YOU});
        dispatch(hideLoading());

        const userData = selectLoggedInUserData(getState());

        const userCompany = userData.company;

        const {groupVolunteers} = caseData.tasks[0];

        const redirectUrl = ( caseData.isMultipleVolunteers &&
            ((!caseData.whiteLabel && userCompany && groupVolunteers.length === 1)
                || (caseData.whiteLabel && caseData.whiteLabel.id === userCompany.id)) )
            ? `/c/${caseData.serialNumber}/gr` : '';

        if (redirectUrl) {
            const userWhitelabel = userData.company && userData.company.whiteLabelSubdomain && userData.company.whiteLabelSubdomain.length ? userData.company.whiteLabelSubdomain : null;
            history.navigate(redirectUrl, userWhitelabel);
        }

    } catch (e) {
        if(e.response && e.response.data.errors){
            const errors = ERROR_MESSAGE_TRANSLATE[e.response.data.errors] ||   e.response.data.errors;
            showErrorToast(errors)
        }
        dispatch(hideLoading());
    }
};


/**
 * send the user to the server and add him to the casecase
 * @param caseId
 * @param volunteerId
 * @returns {Function}
 */
export const removeVolunteerFromGroupCase = (caseId, volunteerId) => async (dispatch) => {

    try {
        const response = await CaseService.deleteVolunteer(caseId, volunteerId);
        const {data} = response;

        dispatch({type: actionTypes.GET_CASE_GROUP_VOLUNTEERS, payload: MapperVolunteers.mapToVm(data)});

    } catch (e) {

    }
};

export const copySuccessToast = () => showSuccessToast('Copied!');

export const handleVolunteerModals = () => async (dispatch, getState) => {
    
    const volunteerId = selectLoggedInUserId(getState());
    const response = await CaseService.getCasesByVoluteer(volunteerId);

    const isEmailVerified = selectLoggedInReqActionsEmail(getState());
    
    if(!isEmailVerified){
        
        dispatch({type: actionTypes.EMAIL_NOT_VERIFIED});
        
    }else if(response.data.num_cases < 3) {
        dispatch({type: actionTypes.CAN_BE_VOLUNTEER});
    }
    else {
        dispatch({type: actionTypes.TOO_MANY_VOLUNTEERING});
    } 
}