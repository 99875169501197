import React, {Component} from 'react';
import {connect} from "react-redux";
import * as selectors from "./selectors.js";
import * as actions from './actions';
import * as rootSelectors from '../root-reducer/selectors';
import {IS_SUBDOMAIN} from "../../../common/constants";

export default (SuccessGui) => {
    const mapStateToProps = (state) => ({
        successes: selectors.selectSuccesses(state),
        whiteLabel: rootSelectors.selectWhiteLabel(state),
    });

    const mapDispatchToProps = (dispatch) => ({
        getSuccesses: (params) => dispatch(actions.getSuccesses(params))
    });

    class SuccessWrapper extends Component {

        constructor(props) {
            super(props);

            this.state = {
                called: false
            }
        }

        componentDidMount() {
            const {whiteLabel} = this.props;
            if(!IS_SUBDOMAIN) {
                this.props.getSuccesses({
                    whiteLabelId: null,
                    whiteLabelType: null,
                    number: null
                })
            } else if (whiteLabel && whiteLabel.id) {
                this.props.getSuccesses({
                    whiteLabelId: whiteLabel.id,
                    whiteLabelType: whiteLabel.type,
                    number: null
                });
                this.setState({called: true})
            }
        }

        componentDidUpdate(prevProps) {
            const {whiteLabel} = this.props;

            if(IS_SUBDOMAIN && this.props.whiteLabel.id && !this.state.called) {
                this.props.getSuccesses({
                    whiteLabelId: whiteLabel && whiteLabel.id ? whiteLabel.id : null,
                    whiteLabelType: whiteLabel && whiteLabel.type ? whiteLabel.type : null,
                    number: null
                });
                this.setState({called: true})
            }
        }

        render() {
            return (
                <SuccessGui
                    successes={this.props.successes}
                    whiteLabel={this.props.whiteLabel}
                />
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(SuccessWrapper);
};
