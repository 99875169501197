import React from 'react';
import './style.scss';
import {FOOTER_MENU_ITEMS} from "../../common/constants"
import FooterMenu from "./footer-menu";
import FooterSocial from "./footer-social";
import {withRouter} from 'react-router-dom';
import TavMidot from '../../assets/img/tav_midot_09-25.png';
import facebookLogo from '../../assets/img/footer/Icon-Social-Facebook.svg'

class Footer extends React.PureComponent {

    getClass(){
        const {pathname} = this.props.location;


        return "";
    }

    render() {
        return (
            <footer className={`${this.getClass()}`}>
               <div className={"footer-container"}>
                <FooterSocial />
                   <div className={"section-seperator"}> </div>
                   <div className={"logo"}> </div>
                   <FooterMenu items={FOOTER_MENU_ITEMS} />

                    
                   <div className={`tav`}><img src={TavMidot} alt={"תו מידות לאפקטיביות"}/></div>
               </div>
            </footer>

        );
    }
}

Footer.propTypes = {
};

export default withRouter(Footer);