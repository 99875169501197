import React from 'react';
import { Component } from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";

class ItemMenu extends Component{


    constructor(props){
        super(props)

        this.state = {
             hiddenSubMenu: true
        }

    }


    
    /**
     * generate link according to item metadata
     * @param item
     * @returns {*}
     */
   generateLink = (item, addClass) => {
       const link = item.genLink ? item.genLink.apply(null, [this.props.user]) : item.link;

       return item.isExternal ?
           <a href={link} className={addClass}  target={"_blank"} item-label = {item.label}>{item.label}</a>
           :
           <NavLink className={addClass} activeClassName="active" to={link} target={item.target} item-label = {item.label}>{item.label}</NavLink>
   };

   /**
    * return the item class active if one of his sub items is active
    * @param item
    * @returns string
    */
   getItemClass = (item) => {
       const {pathname} = this.props.location;
       const {subItems} = item;

       if (!subItems) return undefined;

       return subItems.findIndex(({link}) => link === pathname) !== -1 ? 'active' : undefined;
   };

   showSubItems = () => {
       
     
       this.setState({
           ...this.state,
           hiddenSubMenu: false
       })
   }

   hideSubItems = () => {
       this.setState({
           ...this.state,
           hiddenSubMenu: true
       })

   }

    /**
     * generates item content
     * @param item
     * @returns {*}
     */

    render(){

        const {item} = this.props


        return(
       
            <div className={`nav-bar-main-link ${item.classes} ${item.subItems ? 'arrow' : ''}`}
            onMouseEnter={item.subItems ?  this.showSubItems : null}
            onTouchStart={item.subItems ? this.showSubItems : null}
            onMouseLeave={item.subItems ? this.hideSubItems : null}
            onClick={!item.subItems ? this.hideSubItems : null}
       >
           {(!item.link) ? <label className={`${this.getItemClass.apply(null, [item]) || ""} hoverMenu nav-bar-main-link`} item-label = {item.label + 'nn'}>{item.label}</label> : this.generateLink(item, 'hoverMenu')}
           {
               item.subItems && (
                   <ul className={`sub-menu ${ this.state.hiddenSubMenu ? 'hidden' : ''} `}>
                       {item.subItems.map((subItem, index) => <li
                           onClick={this.hideSubItems}
                           key={`${subItem.link}-${index}`}
                           className={"sub-menu-item"}
                       >
                
                           {this.generateLink(subItem)}</li>)}
                   </ul>
               )
           }
              
       </div>
        )
    }


 

}

export default ItemMenu

ItemMenu.propTypes ={

    item: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}