import React from 'react';
import ContactForm from "../../components/contact-form";
import ContactMap from "../../components/contact-map";
import ContactWrapper from '../../core/containers/contact';
import contactImg from '../../assets/img/contact-page/Group-2316.png'
import './style.scss';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import contactText from '../../assets/img/contact-page/contact-text.svg'

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formSubject: this.props.match.params.formSubject || ''
        }
    }

    componentDidMount(){
        window.scroll(0,0);
    }

    componentWillUnmount() {
        this.props.hideMessage();
    }

    render() {
        const successMessage = (this.props.showSuccess) ?
            <div className={"send-success"}>הטופס נשלח בהצלחה!</div>
            : null;
     
        return (
            <div className={"contact-container"}>
                <div className='image-wrapper'>
                    <img src={contactImg} />
                </div>
                <div className='contact-form'>
                    <h1 className='title-form'>יצירת קשר</h1>
                        {successMessage}     
                    <ContactForm
                        sendContactForm={this.props.sendContactForm}
                        subject={this.state.formSubject}
                        disableSendButton={this.props.disableSendButton}
                    />
                </div>
            </div>
        );
    }
}

Contact.propTypes = {
    sendContactForm: PropTypes.func,
    disableSendButton: PropTypes.bool
};

export default ContactWrapper(withRouter(Contact));