import { includes } from "lodash";

class Utils {
    /**
     * pick n random items from an array
     * @param arr
     * @param num
     * @returns {Array}
     */
    pickRandomItems(arr, num) {
        let size = 0;
        let result = [];
        let arrCopy = [...arr];

        num = num > arrCopy.length ? arrCopy.length : num;

        while(size < num) {
            let index = Math.floor(Math.random()*arrCopy.length);
            result.push(arrCopy[index]);

            arrCopy.splice(index, 1);

            size++;
        }

        return result;
    }

    /**
     * add an 'all' item to filters lists if necessary
     * @param arr
     */
    addAllItem = (arr) => [{value: '*all*', displayName: 'הכל'}, ...arr];

    /**
     * converts Camel Case to underscore_case for sending to server
     * @param key
     * @returns {string}
     */
    camelToUnderscore = (key) => {
        return key.replace(/([A-Z])/g, "_$1").toLowerCase();
    };

    underToCamelCase = (key) => {
            return key.replace(/(_[a-z])/g, ($1) => $1.toUpperCase().replace('_',''));
    };
    
    camelObjToUnderscore = (obj) => {
        let newObj = {};

        Object.keys(obj).forEach((key) => {
            const newKey = Utils.camelToUnderscore(key);
            newObj[newKey] = obj[key];
        });

        return newObj;
    };

    /**
     * helper builder for form schemes
     * @param fields
     * @returns {{}}
     */
    buildFormScheme = (fields) => Object.entries(fields).reduce((acc, entry) => {
        const name = entry[0], meta = entry[1];
        acc[name] = {
            error: '',
            value: meta.defaultValue || ''
        };

        return acc;
    }, {});

    /**
     *
     * @param state
     * @returns {boolean}
     */
    validateFormScheme = (state) => Object.entries(state).reduce((valid, entry) => (
        valid && entry[1].error === ''
    ), true);

    parseNewLine = text => `<ul>${text.split('\n').filter(line => line.trim()).map(line => `<li>${line}</li>`).join('')}</ul>`;
      

    handleCheckboxClick = (name, value, currItems, map) => {
        
        const centerRgione = 'center';
        const subregions = ['sharon','lowland_coast','gush_dan'];
        let items;
        if(value === '*all*') {
            items = currItems.length === map.length ? [] : map.map((item) => item.value)
        }
        else if (currItems.indexOf(value) > -1) {
            if(value === centerRgione){
                items = currItems.filter((task) => task !== value && !subregions.includes(task)) 
            }
            else if(subregions.includes(value) && currItems.includes(centerRgione)){
                let index = currItems.indexOf(centerRgione);
                currItems.splice(index, 1)
                items = currItems.filter((task) => task !== value);
            }
            else items = currItems.filter((task) => task !== value);
        } else {
            if(value === centerRgione){
                items = [...currItems, value, ...subregions]
                items = items.filter((item,index)=>{
                    return (items.indexOf(item) == index)
                 })
            }
            else if(subregions.includes(value)) {
                if(currItems.filter((task) => subregions.includes(task)).length === 2){
                    currItems.push(centerRgione);
                }
                items = [...currItems, value]
                }
            
            else items = [...currItems, value]
        }
        return items;
    }

    /**
     * stolen shamelessly from MDN:
     * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
     * @param type
     * @return {boolean}
     */
    storageAvailable  = (type) => {
        let storage;
        try {
            storage = window[type];
            let x = '__storage_test__';

            storage.setItem(x,x);
            storage.removeItem(x);
            return true;
        } catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }

}

export default new Utils();
