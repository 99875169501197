import React from 'react';
import AuthWrapper from '../../core/containers/auth';
import './style.scss';
import PropTypes from "prop-types";
import AuthConsumer from './../../core/containers/auth/auth-protected';
import history from './../../utils/history';
import AuthBox from "../../components/auth-box";
import SignUpBox from "../../components/signup-box";
import HelpiButton from '../../components/helpi-button';
import {HELPI_BUTTON_CLASSES, EVENTS, IS_MOBILE, USER_ROLES} from '../../common/constants';
import RegistrationSuccess from '../../components/registration-success';
import GAService from 'react-ga';

class Auth extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            registering: false,
            mobileEnterClick: false
        }
    }

    componentWillUnmount() {
        this.props.resetLogoColor();
        this.props.resetHeaderClass();
    }

    componentDidMount(){
        window.scrollTo(0,0);

        const session = sessionStorage.getItem('institute_id');
        if(!IS_MOBILE) {
            this.props.toggleLogoColor();
        } else {
            // this.setState()
        }

        if(this.props.user || session) {
            this.switchStates();
        }
    }

    componentDidUpdate(prevProps){
        if(IS_MOBILE) {
            if(!prevProps.registrationSuccess && this.props.registrationSuccess) {
                this.props.setSpecialHeaderClass('transparent');
            }
        }
    }

    /**
     * leave login page
     */
    leave = () => {
        this.props.resetRegistration();
        this.props.resetValidError();
        this.props.leave();
    };

    /**
     * Toggle between login and sign up states
     */
    switchStates = (e) => {
        if(!IS_MOBILE) {
            this.setState({registering: !this.state.registering});
        }
        this.props.resetValidError();
        if(document.documentElement.clientWidth > 450) {
            this.props.toggleLogoColor();
        } else {
            this.setState({...this.state, registering: true});
        }

        if(e && !e.target.className.split(' ').includes('orange')) {
            GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.SIGN_UP, label: 'Step 1'});
        }
    };

    /**
     * Slightly different behavior for mobile, sets login/sign up and removes first screen
     * @param evt
     */
    setMobileState = (evt) => {
        const name = evt.target.getAttribute('name');
        const {mobileEnterClick} = this.state;
         if(name === 'mobile-sign-up') {
             this.setState({registering: true, mobileEnterClick: !mobileEnterClick});
         } else {
             this.setState({registering: false, mobileEnterClick: !mobileEnterClick});
         }
    };

    /**
     * generates the divider for desktop and tablet, or the first screen for mobile, directing users to signup or login
     * @returns {*}
     */
    generateDivider = () => {
        const { registering } = this.state;

        if(document.documentElement.clientWidth > 450) {
            const title = registering ? 'כבר רשומ/ה?' : 'יצירת חשבון חדש';
            const text = registering ? 'כיף שחזרת!\n לחצ/י להתחברות' : 'פעם ראשונה כאן?\n לחצ/י להרשמה - זה פשוט\n לעשות טוב'
            const buttonText = registering ? 'כניסה' : 'המשך';
            const buttonClasses = registering ? HELPI_BUTTON_CLASSES.ORANGE : '';

            return (
                <div className={`register-divider ${!registering ? 'login' : 'sign-up'}`}>
                    <div className="text-wrapper">
                        <h2>{title}</h2>
                        <p>{text}</p>
                        <HelpiButton
                            label={buttonText}
                            classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG, buttonClasses]}
                            onClick={this.switchStates}
                        />
                    </div>
                </div>
            )
        } else {
            return !this.state.mobileEnterClick
                ?
                <div className="mobile-switch-screen">
                    <div className="to-sign-up" name="mobile-sign-up" onClick={this.setMobileState}>
                        <h2>פעם ראשונה כאן?</h2>
                        <div>לחצ/י להרשמה</div>
                    </div>
                    <div className="to-login" name="mobile-login" onClick={this.setMobileState}>
                        <h2>כבר רשומ/ה?</h2>
                        <div>לחצ/י להתחברות</div>
                    </div>
                </div>
                :
                null;
        }
    };

    handleAddUserDetails = (context) => this.setState({...this.state, registering: !this.state.registering});

    returnToMobileFront = () => {
        this.props.resetRegistration();
        this.setState({registering: false, mobileEnterClick: false});
    };

    handleUpdateUser = (payload, sendVerification, isFinalStage=false) => this.props.updateUser(payload, sendVerification, isFinalStage, this.props.location.state)

    /**
     * renders wither an X for desktop and tablet or an Arrow for mobile
     * @returns {XML}
     */
    generateNavItem = () => {
        const {mobileEnterClick} = this.state;

        if(document.documentElement.clientWidth > 450 || !mobileEnterClick) {
            return <div className={`close ${this.state.registering ? 'sign-up' : 'login'}`} onClick={this.leave} />
        }
        return <div className={`mobile-switch-arrow ${this.state.registering ? 'sign-up' : 'login'}`} onClick={this.returnToMobileFront}/>
    };

    checkSMSCode = (payload) => this.props.checkSMSCode(payload, this.props.location.state);

    generateContent = () => {
        const divider = this.generateDivider();

        const navItem = this.generateNavItem();

        return (
            <div className={`auth ${this.state.registering ? 'sign-up' : 'login'}`}>
                {navItem}
                <div className={'box-wrap'}>
                    {
                        !this.state.registering &&
                        <AuthBox
                            key={'auth-box'}
                            name="login"
                            onSubmit={this.props.login}
                            onFBSubmit={this.props.fbLogin}
                            forgetPasswordRequest={this.props.forgetPasswordRequest}
                            forgetPassword={this.props.forgetPassword}
                            resetForgetPassword={this.props.resetForgetPassword}
                            handleAddUserDetails={this.handleAddUserDetails}
                            verificationStage={this.props.verificationStage}
                            loginContext={this.props.location.state || null}
                            loginCompleteDetails={this.props.loginCompleteDetails}
                            onCompleteDetailsSubmit={this.handleUpdateUser}
                            checkSMSCode={this.props.checkSMSCode}
                            resendSMSCode={this.props.resendSMSCode}
                            validError={this.props.validError}
                            resetValidError={this.props.resetValidError}
                        />
                    }
                    
                    {
                        this.state.registering &&
                        <SignUpBox errors={this.props.errors.signUp}
                                   stage={this.props.signUpStage}
                                   verificationStage={this.props.verificationStage}
                                   key={'signup'}
                                   onLoginDetailsSubmit={this.props.signUp}
                                   onCompleteDetailsSubmit={this.handleUpdateUser}
                                   onFBSignup={this.props.fbLogin}
                                   checkSMSCode={this.checkSMSCode}
                                   resendSMSCode={this.props.resendSMSCode}
                                   user={this.props.user}
                                   signUpContext={this.props.location.state || null}
                                   validError={this.props.validError}
                                   resetValidError={this.props.resetValidError}
                                   loginCompleteDetails={this.props.loginCompleteDetails}
                                   emailDomainAuthorized={this.props.emailDomainAuthorized}
                                   checkEmailDomain={this.props.checkEmailDomain}
                                   emailDomainType={this.props.emailDomainType}
                                   companies={this.props.companies}
                        />
                    }
                    {divider}
                </div>
            </div>
        )
    };

    handleRegistrationClick = () => {
        if(this.props.pendingTask) {
            this.props.handlePendingTask(false, this.props.user);
        } else {
            const path = this.props.location.state ? (this.props.location.state.returnPath || '/') : '/';
            const ext = this.props.user.whiteLabelSubdomain ? this.props.user.whiteLabelSubdomain : undefined;
            history.navigate(path, ext);
        }

        if(this.props.registrationSuccess) {
            this.props.resetRegistration();
        }
    };

    handleEmailToken = ()  => this.props.generateEmailToken(this.props.user.id)

    render() {
        const authBoxContent = this.generateContent();

        return !this.props.registrationSuccess
            ?
            authBoxContent
            :
            <div className={`auth ${document.documentElement.clientWidth < 450 ? 'mobile-success' : ''}`}>
                {document.documentElement.clientWidth < 450 ? <div className={`close ${this.state.registering ? 'sign-up' : 'login'}`} onClick={this.leave}></div> : null}
                <RegistrationSuccess
                    user={this.props.user}
                    onClick={this.handleRegistrationClick}
                    fromVolunteer={this.props.location.state ? /\/c\//.test(this.props.location.state.returnPath) : false}
                    subtitle={this.props.user && !this.props.user.fbUser ? `כדי להתחיל ${this.props.user && this.props.user.role && this.props.user.role === USER_ROLES.SOCIAL_WORKER ? 'להשתמש באתר הלפי' : 'להתנדב בהלפי'}, מייל אימות מחכה לך בכתובת הדוא״ל שלך,` : null}
                    message={this.props.user && !this.props.user.fbUser ? <h3>  לא קיבלת? לשליחה חוזרת <span className="link" onClick={this.handleEmailToken}>לחצ/י כאן</span> </h3> : null}
                />
            </div>
    }
}

Auth.propTypes = {
    login: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,
    errors: PropTypes.object,
    fbLogin: PropTypes.func.isRequired
};

export default AuthWrapper(AuthConsumer(Auth));
