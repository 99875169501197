import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ClickOutside from '../click-outside';
import CheckboxWrapper from './../checkbox-wrapper';
import './_style.scss';

class MultiSelect extends PureComponent {

    openList = () => this.props.open();

    onChange = (value) => {this.props.onChange(this.props.name, value)};

    generateChoice = (item) => {
        const {value, data, coloredCheckbox} =  this.props;
        const checked = value.indexOf(item.value) > -1 || value.length === data.length - 1;

        return (
            <CheckboxWrapper
                label={item.displayName}
                className={`multi-select-checkbox ${checked ? 'checked' : ''}`}
                colored={coloredCheckbox}
                value={item.value}
                onChange={this.onChange}
                checked={checked}
            />
        )
    };

    generateChoices = () => {
        const {data, label, labelInDropdown, noScroll, alignOnBottom} = this.props;
        let list;
        if(label === "איפה נוח לכם?"){
            list = data.map((item, i) => <li key={`list-item-${i}`} className={`list-item region`}>{this.generateChoice(item)}</li>);
        }else{
            list = data.map((item, i) => <li key={`list-item-${i}`} className="list-item">{this.generateChoice(item)}</li>);
        }

        const labelContainer = label  && labelInDropdown ? <li className="label-display">{label}</li> : null;

        return (
            <div className={`multi-select-list-wrapper ${label ? 'with-label' : ''} ${noScroll ? 'no-scroll' : ''} ${alignOnBottom ? 'align-bottom' : ''}`}>
                <ul className="list">
                    {labelContainer}
                    {list}
                </ul>
            </div>
        )
    };


    render() {
        //readOnly='true' for input to prevent both user's typing into the input but also prevents google autofill (HELPI-1130)
        const { label, name, value, data, closed, placeholder, labelAbove, generalFont } = this.props;
        const choices = this.generateChoices();
        const selectValue = value
            .map((item) => {const o = data.find((obj) => obj.value === item); return o['displayName']})
            .join(', ');

        return (
                <div className={`multi-select-container ${labelAbove ? 'label-above' : ''} ${name}`}>
                    {labelAbove ? <label className="multi-select-label" htmlFor={`multi-select-${name}`}>{label}</label> : null}
                    <input
                        placeholder={placeholder}
                        type={'text'}
                        name={`multi-select-${name}`}
                        value={selectValue}
                        onClick={this.openList}
                        onChange={this.onChange}
                        className={`multi-select-input ${generalFont ? 'general-font' : ''}`}
                        readOnly={true}
                        id={this.props.id}
                    />
                    {!closed ? choices : null}
                </div>
        )
    }
}

MultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.array.isRequired,
    closed: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    coloredCheckbox: PropTypes.string,
    labelAbove: PropTypes.bool,
    labelInDropdown: PropTypes.bool,
    noScroll: PropTypes.bool,
    alignOnBottom: PropTypes.bool,
    generalFont: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        displayName: PropTypes.string
    })).isRequired
};

MultiSelect.defaultProps = {
    labelAbove: false,
    labelInDropDown: true,
    noScroll: false,
    alignOnBottom: false
};

//class names here are internal to react-toolbox library, but are necessary to prevent unwanted closing
export default ClickOutside(MultiSelect, ['multi-select-input', 'multi-select-container', 'list', 'list-item', '_271V1', '_1CXAo', '_3Tq32']);