import * as selectors from './selectors';
import * as actionTypes from './action-types';
import {CAN_BE_VOLUNTEER} from '../case/action-types'
import {addVolunteerToCase} from "../case/actions";
import history from './../../../utils/history';
import UserService from './service';
import Network from '../../../utils/network';
import {USER_LOGOUT} from "./action-types";
import Mapper from './mapper';
import {showErrorToast, showSuccessToast} from '../common/toast/actions';
import {EVENTS, IS_SUBDOMAIN} from '../../../common/constants';
import FBService from '../../../common/facebook-service';
import RestService from '../../../common/rest-service';
import {SIGN_UP_CONTEXTS} from '../../../common/constants';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import GAService from '../../../common/analytics-service';
import Cookies from 'universal-cookie';
import * as homeActions from '../homepage/actions';
import Utils from '../../../common/utils';

export const handlePendingTask = (redirectAnyWay, userData) => async (dispatch, getState) => {
    const pendingTask = selectors.selectPendingTask(getState());
    const userWhitelabel = userData.whiteLabelSubdomain && userData.whiteLabelSubdomain.length ? userData.whiteLabelSubdomain : null;

    // if the user tried to enroll but was refused
    if (pendingTask && userData.phoneNumber) {

        dispatch(addVolunteerToCase(pendingTask.caseData.id, pendingTask.taskId));
        dispatch({type : CAN_BE_VOLUNTEER})
        const {caseData} = pendingTask;
        const caseCompanyId = caseData.whiteLabel ? caseData.whiteLabel.id : null;
        const userCompanyId = userData.company ? userData.company.id : userData.organization ? userData.organization.id : '';
        const task = caseData.tasks[0];

        const urlReturn = !!caseCompanyId ? `/c/${caseData.serialNumber}/` :
            !!userCompanyId && caseData.isMultipleVolunteers && task.groupVolunteers.length === 0  ? `/c/${caseData.serialNumber}/gr` :
                `/c/${caseData.serialNumber}`;

                history.navigate(urlReturn);
        GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.VOLUNTEER_REGISTRATION});

        //if there is no pending task, but user needs to be redirected
    } else if (redirectAnyWay) {
        if(redirectAnyWay.returnPath) {
            history.navigate(redirectAnyWay.returnPath, userWhitelabel);
        } else {
            history.navigate('/', userWhitelabel);
        }
    }

    if(redirectAnyWay.context && (redirectAnyWay.context === SIGN_UP_CONTEXTS.GENERATE_EMAIL)){
        dispatch(homeActions.generateEmailToken(userData.id))
    }
};

/**
 * login with user name and password
 * @param email
 * @param password
 * @param redirect
 * @param recaptchaToken
 * @returns {Function}
 */
export const login = ({email, password, redirect, recaptchaToken}) => async (dispatch) => {
    const result = await UserService.authenticate({email, password, recaptchaToken});
    const {success, data} = result;
    dispatch(showLoading());

    if (success) {
        Network.setToken(data);
        const cookie = new Cookies();
        cookie.set('token', data, !document.domain.split('.').includes('helpistaging') &&  {domain: `.${IS_SUBDOMAIN ? document.domain.split('.').slice(1).join('.') : document.domain}`, path: '/', sameSite: 'strict', secure: true});
        if(process.env.NODE_ENV === 'development') {
            sessionStorage.setItem('token', data);
        }
        const resp = await UserService.getUserDetails();
        const userData = Mapper.mapToVm({user: resp.data});
        if(!userData.user.reqActions.email) {
            dispatch(homeActions.generateEmailToken(userData.user.id));
        }


        if (typeof redirect === 'object' && redirect.context === SIGN_UP_CONTEXTS.FULL && !userData.user.reqActions.email) {
            dispatch({type: actionTypes.LOGIN_COMPLETE_DETAILS});
            dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: {...userData}});
            dispatch(hideLoading());

        } else {
            dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: {...userData}});
            dispatch(hideLoading());
            dispatch(handlePendingTask(redirect, {...userData.user}));
        }
    } else {
        dispatch({type: actionTypes.SHOW_VALID_ERROR, payload: data});
        dispatch(hideLoading());
    }
};

/**
 * if there's a valid token, set it and use it
 * @returns {Function}
 */
export const authTest = () => async (dispatch) => {
    if(Utils.storageAvailable('sessionStorage')) {
        const cookie = new Cookies();
        const token = cookie.get('token') || sessionStorage.getItem('token');

        if (token) {
            try {
                // set auth header
                Network.setToken(token);
                const resp = await UserService.getUserDetails();
                dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: Mapper.mapToVm({user: resp.data})});
            } catch (e) {
                UserService.logOut();
            }
        }
    }

    dispatch({ type: actionTypes.AUTH_TEST_DONE });
};

/**
 * clear token and logout
 * @returns {Function}
 */
export const userLogOut = () => async (dispatch) => {
    await UserService.logOut();

    dispatch({ type: USER_LOGOUT });
};

/**
 * handle login with facebook API
 * @param redirect
 */
export const facebookLogin = ({redirect, recaptchaToken}) => async (dispatch) => {
    const recaptcha = UserService.checkRecaptcha(recaptchaToken);

    if(recaptcha) {
        await FBService.login(async (res) => {
            dispatch(showLoading());
            const {authResponse} = res;
            try {
                const fbResponse = await RestService.get(`login/fb_token/${authResponse.accessToken}`);

                try {
                    const {data, success} = fbResponse;

                    if (success) {

                        Network.setToken(data);
                        const cookie = new Cookies();
                        cookie.set('token', data, {domain: `.${IS_SUBDOMAIN ? document.domain.split('.').slice(1).join('.') : document.domain}`, path: '/', sameSite: 'lax'});

                        //TODO add login context for label
                        GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.FB_LOGIN_SUCCESS})

                        const resp = await UserService.getUserDetails();
                        const mappedPayload = Mapper.mapToVm({user: resp.data});

                        if (redirect && (redirect.context === SIGN_UP_CONTEXTS.FULL || redirect.context ===  SIGN_UP_CONTEXTS.COMPLETE_DETAILS) && !mappedPayload.user.reqActions.email) {
                            dispatch({type: actionTypes.LOGIN_COMPLETE_DETAILS});
                            dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: {...mappedPayload}});
                            dispatch(hideLoading());

                        } else {
                            dispatch({type: actionTypes.USER_AUTHENTICATION_SUCCESS, payload: mappedPayload});
                            dispatch(handlePendingTask(redirect, {...mappedPayload.user}));
                            dispatch(hideLoading());
                        }

                    }

                } catch (e) {
                }
            } catch (e) {
                dispatch({type: actionTypes.SHOW_VALID_ERROR, payload: 'SERVER_ERROR'});
                dispatch(hideLoading());
                //TODO add login context for label
                GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.FB_LOGIN_FAILURE})

            }

        });
    }

};

/**
 * send request for forgot password token
 * @param email
 */
export const forgetPassword = (email) => async (dispatch) => {
        const response = await RestService.post(`api/forgetpassword`, {email: email});
        const { success } = response;
        if (success) {
            dispatch({type: actionTypes.FORGOT_PASSWORD_SENT, payload: response});
        } else {
            showSuccessToast('במידה וחשבונך קיים ישלח מייל בהתאם')
        }
};

/**
 * update user password
 * @param password
 * @param token
 */
export const updatePassword = (password, token) => async (dispatch) => {

    const response = await RestService.put(`api/forgetpassword`, {password, token});
    if(response) {
        dispatch({type: actionTypes.UPDATE_FORGOTTEN_PASSWORD, payload: response});
        return response;
    }
};

export const sendToken = (token, type) => async () => {
    const url = type === 'password' ? 'api/forgetpassword' : 'api/subscriber/unsubscribe';

    return await RestService.get(`${url}/${token}`);

};