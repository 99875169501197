


import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import "./_style.scss";
import defaultImg from '../../assets/img/success/helpi_logo.svg';

class SuccessCard extends PureComponent {

    generateCardImage = () => {
        const { image } = this.props;
        const imageSrc = image ? image : defaultImg;
        return (
            <div className="story-circle" style={{backgroundImage: `url('${imageSrc}')`}}>
                {/*<span className="blue-bubble" />*/}
            </div>
        )
    };

    generateCard = () => {
        const { isLink, link, text } = this.props;
        const cardImageContent = this.generateCardImage();
        const cardTextContent = <div className="story-title">{text}</div>;

        if(isLink) {
            return (
                <Link to={link}>
                    {cardImageContent}
                    {cardTextContent}
                </Link>
            )
        } else {
            return (
                <div>
                    {cardImageContent}
                    {cardTextContent}
                </div>
            )
        }
    };

    render() {
        const card = this.generateCard();
        return (
            <div className="success-card-container">
                {card}
            </div>
        )
    }

}

SuccessCard.propTypes = {
    isLink: PropTypes.bool,
    link: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string
};

export default SuccessCard;
